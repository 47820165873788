.features{
    padding-top: 100px;
    padding-bottom: 100px;
}

.features .features-h{
    font-family: 'Roboto', sans-serif;
    font-size: 45px;
    color: var(--purple);
}

.features .features-row{
    margin: 15px;
}

.features .features-row .feature{
    background-color: var(--lilac);
    padding: 60px;
    margin: 15px;
    animation: glow 800ms ease-out infinite alternate;
}

.features .features-row .feature .feature-icon-border{
    background-color: var(--light-purple);
    width: 30%;
    padding: 12px 10px;
    border-radius: 100px;
    font-size: 24px;
}

.features .features-row .feature .feature-icon-border .feature-icon{
    color: #ffffff;
}

.features .features-row .feature .feature-h{
    margin: 10px;
    font-size: 20px;
    color: var(--purple);
}

.feature-txt{
    font-size: 14px;
}

@keyframes glow {
    0% {
		border-color: var(--lilac);
		box-shadow: 0 0 5px var(--light-purple), inset 0 0 5px var(--light-purple), 0 2px 0 #fff;
    }	
    100% {
		border-color: var(--lilac);
		box-shadow: 0 0 20px var(--light-purple), inset 0 0 10px var(--light-purple), 0 2px 0 #fff;
    }
}

@media (max-width: 1199px){
    .features .features-row .feature{
        background-color: var(--lilac);
        padding: 35px;
        margin: 15px;
        animation: glow 800ms ease-out infinite alternate;
    }    
}

@media (max-width: 768px){
    .features .features-h{
        font-size: 25px;
    }
}