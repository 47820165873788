.footer{
    background-image: linear-gradient(#f9f8ff00, #D4BEEC);
    text-align: center;
    margin-top: auto;
    padding-top: 20px;
    padding-bottom: 10px;
}

.footer-socials{
    margin: 5px;
}

.footer-socials .footer-a{
    color: var(--purple);
    margin: 15px;
}

.footer .footer-mail .footer-mail-txt{
    color: #000000;
}

.footnote{
    border-top: #D4BEEC solid 1px;
    padding-top: 10px;
    margin-top: 50px;
}

.footnote .footer-heart{
    color: var(--purple);
}

.footnote a{
    color: var(--purple);
}