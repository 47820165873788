@import url('https://fonts.googleapis.com/css2?family=Nunito&family=Roboto:wght@900&display=swap');

.App{
  cursor: url(icon.svg), auto;
  overflow-x: hidden;
}

body{
  font-family: 'Nunito', sans-serif;
  background-color: var(--light);
}

h1, h2, h3, h4, h5, h6{
  font-family: 'Roboto', sans-serif;
}

a{
  text-decoration: none;
}

.btn{
  background-color: var(--light-purple);
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 20px;
  border: 1px solid white;
  color: #010403;
}

.btn:hover{
  color: white;
  background-color: var(--purple);
  border: 1px solid white;
}

:root{
  --light: #F9F8FF;
  --lilac: #EEEBFF;
  --purple: #8736B4;
  --light-purple: #D4BEEC;
  --black: #010403;
}

