.top{
  background-image: url(bg.png);
  background-repeat: none;
  background-size: cover;
}

.header{
  background-image: linear-gradient(#D4BEEC, #f9f8ff00);
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-small{
  display: none;
}

.header-big .nav-icon{
  width: 50px;
  height: auto;
}

.header-big .nav-renders{
  float: right;
}

.header-big .nav-renders .nav-txt{
  align-self: center;
  margin: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  color: var(--black);
  font-family: 'Nunito', sans-serif;
}

.nav-txt:hover{
  border-bottom: var(--purple) 1px solid;
}

.waitlist{
  margin-top: 40px;
  width: 60%;
}

.waitlist-txt{
  font-size: 17px;
}

.brand-name{
  color: var(--purple);
  font-family: 'Roboto', sans-serif;
  font-size: large;
}

.emph{
  color: var(--purple);
  background-color: var(--lilac);
  border-bottom: var(--purple) 1px solid;
}

.waitlist-mock{
  clear: both;
  width: 55%;
  margin: auto;
}

.modal-content{
  background-color: var(--lilac);
  padding: 20px;
  animation: glow 800ms ease-out infinite alternate;
}

.modal-header{
  font-family: 'Roboto', sans-serif;
  color: var(--purple);
}

.form-label{
  margin-bottom: 0;
}

.form-control{
  background-color: transparent;
}

.input-group{
  margin-bottom: 10px;
  border: var(--light-purple) 1px solid;
}

.input-group-text{
  width: 50px;
  background-color: transparent;
  color: var(--purple);
}

.modal-up{
  margin-bottom: 10px;
  border: var(--light-purple) 1px solid;
}

.alert{
  background-color: var(--lilac);
  padding: 20px;
  animation: glow 800ms ease-out infinite alternate;
}

@keyframes glow {
  0% {
  border-color: var(--lilac);
  box-shadow: 0 0 5px var(--light-purple), inset 0 0 5px var(--light-purple), 0 2px 0 #fff;
  }	
  100% {
  border-color: var(--lilac);
  box-shadow: 0 0 20px var(--light-purple), inset 0 0 10px var(--light-purple), 0 2px 0 #fff;
  }
}

@media (max-width: 991px){
  .header-big{
    display: none;
  }

  .header-small{
    display: inline;
    margin: 20px;
  }

  .header-small .nav-icon{
    width: 40px;
    height: auto;
  }

  .header-small .icon-left{
    margin-left: 20px;
    float: left;
  }

  .header-small .icon-right{
    margin-right: 20px;
    float: right;
  }

  .header-small .nav-txt{
    display: block;
    margin: 10px;
    color: var(--black);
  }
}

@media (max-width: 982px){
  .waitlist-mock{
    width: 85%;
  }
}

@media (max-width: 768px){
  .top{
    background-image: none;
  }
  
  .waitlist{
    width: 80%;
  }

  .waitlist-txt{
    font-size: 15px;
  }
}