.faq{
    padding-top: 100px;
    padding-bottom: 60px;
}

.faq .faq-h{
    margin: 20px;
    font-family: 'Roboto', sans-serif;
    font-size: 45px;
    color: var(--purple);
    text-align: center;
}

.accordion-button{
    padding: 20px 60px;
    font-family: 'Nunito', sans-serif;
}

.accordion-button:not(.collapsed){
    color: var(--black);
    background-color: var(--light-purple);
}

.accordion-body{
    padding-left: 60px;
    padding-right: 60px;
}

@media (max-width: 768px){
    .faq .faq-h{
        font-size: 25px;
    }
}