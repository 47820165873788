.vision{
    padding-top: 150px;
    padding-bottom: 150px;
    background-color: var(--purple);
    background-size: cover;
    background-image: url(bg.svg);
    background-repeat: none;
}

.vision-h{
    font-family: 'Roboto', sans-serif;
    font-size: 65px;
    color: var(--lilac);
}

.vision-txt{
    width: 60%;
    font-size: 20px;
    color: var(--light-purple);
}

.vision-txt .vision-icon-left{
    color: var(--lilac);
    margin-right: 15px;
}

.vision-txt .vision-icon-right{
    color: var(--lilac);
    margin-left: 15px;
}

@media (max-width: 768px){
    .vision{
        background-position: -40px;
        background-image: url(bg.svg);
        background-repeat: none;
    }
    
    .vision-h{
        font-size: 40px;
    }

    .vision-txt{
        width: 80%;
        font-size: 15px;
        color: var(--light-purple);
    }
}