.hiworks{
    background-color: var(--light-purple);
    padding-top: 100px;
}

.hiworks .hiworks-h{
    font-family: 'Roboto', sans-serif;
    font-size: 45px;
    color: var(--purple);
    text-align: center;
    margin-bottom: 20px;
}

.hiworks .hiworks-footnote{
    background-color: var(--purple);
    text-align: center;
    padding: 50px;
    font-size: 20px;
    color: var(--light-purple);
}

.hiworks .hiworks-footnote .hiworks-icon-left{
    margin-right: 10px;
}

.hiworks .hiworks-footnote .hiworks-icon-right{
    margin-left: 10px;
}

.change{
    color: var(--black);
}

@media (max-width: 1169px){
    .hiworks .hiworks-tl{
        padding-right: 30px;
        padding-left: 30px;
    }

    .change{
        color: #ffffff;
    }

    .hiworks-button{
        float: left;
    }
}

@media (max-width: 768px){
    .hiworks .hiworks-h{
        font-size: 25px;
    }

    .hiworks .hiworks-footnote{
        font-size: 15px;
    }
}