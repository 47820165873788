.mission{
    background-color: var(--light-purple);
    padding-top: 100px;
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 13%);
}

.mission-il, .mission-mock{
    max-width: 550px;
    height: auto;
}

.mission-txt{
    margin: 100px;
    text-align: left;
}

.mission-txt .mission-h{
    font-family: 'Roboto', sans-serif;
    font-size: 65px;
    color: var(--purple);
}

.mission-txt .mission-subh{
    font-size: 20px;
}

.mission-txt .mission-subh .mission-icon-left{
    color: var(--lilac);
    margin-right: 15px;
}

.mission-txt .mission-subh .mission-icon-right{
    color: var(--lilac);
    margin-left: 15px;
}

.mission-txt .mission-f{
    color: var(--lilac);
    font-size: 28px;
}

.mission-txt .mission-f .mission-f-emph{
    color: var(--purple);
}

@media (max-width: 982px){
    .mission, .mission-txt{
        text-align: center;
    }

    .mission-il, .mission-mock{
        display: none;
    }
}

@media (max-width: 768px){
    .mission-txt{
        margin: 40px;
    }

    .mission-txt .mission-h{
        font-size: 40px;
    }

    .mission-txt .mission-subh{
        font-size: 15px;
    }

    .mission-txt .mission-f{
        font-size: 20px;
    }
}